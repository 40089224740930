import * as React from 'react'
import Layout from '../../components/layout'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import { Carousel } from 'react-bootstrap'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import Separator from "../../components/separator"
import "@fontsource/montserrat"
import {
  carouselStyle,
  carouselImage,
  containerText
} from '../../lib/collections.module.css'
import {
  buttonsContainer
} from'../../lib/buttons.module.css'

const Collections = ({ 
  data: {
    mdx: {
      frontmatter: { 
        title, 
        subtitle, 
        brochure,
        embeddedImagesLocal, 
        id 
      },
      body,
      slug
    }
  }
}) => {
  return (
    <Layout pageTitle={title}>

      <Carousel className={carouselStyle} variant="dark">
      {embeddedImagesLocal.map((n) => (
        <Carousel.Item key={id}>
          <h1>{title}</h1>
          <GatsbyImage alt='slide' image={getImage(n)} className={carouselImage} />
        </Carousel.Item>
      ))}
      </Carousel>

      <div className={buttonsContainer} style={{width: '75%', margin: 'auto'}}>
        <div>
          <a href={brochure} title="Download Brochure" target="_blank" rel="noreferrer">Brochure</a>
        </div>
        <div>
          <AnchorLink to={`/collections/${slug}/#contact`} title="Get in touch">Get in touch</AnchorLink>
        </div>
      </div>

      <div className={containerText}>
        <h2>{title}</h2>
        <p>{subtitle}</p>
        <MDXRenderer>
          {body}
        </MDXRenderer>
      </div>

      <Separator />
      
    </Layout>
  )
}
export const query = graphql`
  query ($id: String) {
    mdx(frontmatter: {type: {eq: "collections"}}, id: {eq: $id}) {
      frontmatter {
        title
        subtitle
        brochure
        title1
        title2
        title3
        content1
        content2
        content3
        photo1 {
          childImageSharp {
            gatsbyImageData(
              placeholder: TRACED_SVG
              quality: 80
              transformOptions: {cropFocus: CENTER, fit: COVER}
              formats: WEBP
              width: 600
              layout: CONSTRAINED
            )
          }
        }
        photo2 {
          childImageSharp {
            gatsbyImageData(
              placeholder: TRACED_SVG
              quality: 80
              transformOptions: {cropFocus: CENTER, fit: COVER}
              formats: WEBP
              width: 600
              layout: CONSTRAINED
            )
          }
        }
        photo3 {
          childImageSharp {
            gatsbyImageData(
              placeholder: TRACED_SVG
              quality: 80
              transformOptions: {cropFocus: CENTER, fit: COVER}
              formats: WEBP
              width: 600
              layout: CONSTRAINED
            )
          }
        }
        embeddedImagesLocal {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              quality: 90
              blurredOptions: {width: 100}
              transformOptions: {cropFocus: CENTER, fit: COVER}
              formats: WEBP
              width: 1920
              layout: CONSTRAINED
            )
          }
        }
      }
      body
      slug
    }
  }
`
export default Collections